@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Arimo', sans-serif;
}

/* Home.css */
.product-card {
    max-width: 300px;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease-in-out; /* Smooth transition effect */
  
    /* Add blue border on hover */
  }
  .product-card:hover {
    border-color: #4f46e5;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  Link{
    text-decoration: none;
    color: inherit;
  }

